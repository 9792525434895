import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';


import { Loading } from './Loading';
import { Menu } from './Menu';
import { Header } from './Header';
import { API_URL, URL_IMAGES, URL_SVG } from '../utils/config';
import { Sidebar } from './Sidebar';
import { Compartir } from './Compartir';



import { Footer } from './Footer';



export const Equipo = () => {


	// Global
	const [contadorLoading, setContadorLoading] = useState(0);
	const { idEquipo } = useParams();
	const { idTorneo } = useParams();
	//const [dataHeader, setDataHeader] = useState({nombreCampeonato:"", nombreTorneo:""});
	let sidebar = { actual: "equipo", idTorneo, idEquipo };


/*
	useEffect(() => {
		setContadorLoading(1);
		fetch(API_URL + 'Torneo/GetData?idTorneo=' + idTorneo)
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((r) => {
						setDataHeader(r);
					}); break;
					default: break;
				}
			})
			.catch((error) => { })
			.finally(() => { setContadorLoading(-1) });
	}, [idTorneo]);
*/


	const [equipo, setEquipo] = useState({
		"Id": "",
		"Nombre": "",
		"Observaciones": "",
		"ImagenPerfil": "",
		"ImagenEscudo": "",
		"Puntos": "",
		"GF": "",
		"GC": ""
	});



	useEffect(() => {
		setContadorLoading(1);
		fetch(API_URL + 'Torneo/GetEquipo?IdEquipo=' + idEquipo + '&IdTorneo=' + idTorneo)
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((r) => {
						setEquipo(r.equipo);
						
						//document.querySelector('meta[property="og:image"]').setAttribute('content', r.equipo.ImagenPerfil === "" ? equipoDefault : URL_IMAGES + 'Equipo/' + r.equipo.ImagenPerfil);
						document.querySelector('meta[property="og:title"]').setAttribute('content', r.equipo.Nombre);
						document.querySelector('meta[property="og:description"]').setAttribute('content', r.equipo.Observaciones);
						//document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
						
					}); break;
					default: break;
				}
			})
			.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
			.finally(() => { setContadorLoading(-1) });
	}, [idEquipo, idTorneo]);




	return (
		<>
			<Menu idTorneo={idTorneo} seccion="equipo" />
			<Header idTorneo={idTorneo} />
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="equipo">
							<div className="panel-box my-5">
								<div className="titles">
									<h4 className="equipo-title">{equipo.Nombre}</h4>
								</div>
								<div className="equipo-data row">
									<div className="col-lg-12">
										<div className="stats-info">
											<ul className='w-100' >
												<li>Puntos<h3>{equipo.Puntos}</h3></li>
												<li>Goles a Favor<h3>{equipo.GF}</h3></li>
												<li>Goles en contra<h3>{equipo.GC}</h3></li>
											</ul>
										</div>
									</div>
									<div className="col p-3">
										<div className="equipo-imagen-perfil text-center">
											<img
												className={equipo.ImagenPerfil === "" ? 'img img-50' : 'img'}
												src={equipo.ImagenPerfil === "" ? URL_SVG + "equipo.svg" : URL_IMAGES + 'Equipo/' + equipo.ImagenPerfil}
												alt={equipo.Nombre} />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="stats-info">
											<ul className='w-100'>
												<li className='w-100'><h3>{equipo.Observaciones}</h3></li>
											</ul>
										</div>
									</div>
								</div>
								<div id="equipo-gallery"></div>
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="equipo" idTorneo={idTorneo} idEquipo={idEquipo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer/>
		</>
	)
}